import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//DATABASE
import { addListStart, updateListStart } from "../../redux/lists/lists.actions";

//COMPONENTS
import { InputSelect, InputText } from "./Inputs";
import { ButtonPrimary } from "./Buttons";
import { useDispatch } from "react-redux";
import { HiOutlinePlus } from "react-icons/hi";

export function FormAddShoppingItem({currentUser, listsData}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unit, setUnit] = useState("");
  const [asset, setAsset] = useState("/assets/placeholders/ingredientPlaceholder.svg");

  const resetForm = () => {
    setName("");
    setQuantity("");
    setUnit("");
  };

  const handleAddOtherItems = (e) => {
    e.preventDefault();
    if (name && quantity && unit) {
      const newIngredient = {
        name,
        quantity,
        unit,
        asset,
        date: new Date().toISOString(),
        postID: "Other"
      };

      const existingList = listsData && listsData.find(list => list.userID === currentUser.id);
      if (existingList) {
        const updatedList = {
          ...existingList,
          ingredients: [
            ...existingList.ingredients,
            newIngredient
          ]
        };
        dispatch(updateListStart(updatedList));
      } else {
        dispatch(addListStart({ 
          userID: currentUser.id,
          ingredients: [newIngredient] 
        }));
      }
      resetForm();
    }
  };
  
  return (
    <form onSubmit={handleAddOtherItems} id="addCustomItem">
      <div className="flexInput">
      <div className="ingredientBrandName">
      <InputText
        value={name}
        handleChange={(e) => setName(e.target.value)}
        placeholder={t("Dish soap")}
        required
      />
      </div>
      <span className="quantityUnit">
      <InputText
        value={quantity}
        handleChange={(e) => setQuantity(e.target.value)}
        placeholder={t("10")}
        required={name.length > 0}
        disabled={name.length < 1}
      />
      <InputSelect
        value={unit}
        handleChange={(e) => setUnit(e.target.value)}
        required={name.length > 0}
        disabled={name.length < 1}
        options={[
          {
            value: "",
            name: "",
          },
          {
            value: "pcs",
            name: `${t("pcs")}`,
          },
          {
            value: "ml",
            name: `${t("ml")}`,
          },
          {
            value: "g",
            name: `${t("g")}`,
          },
          {
            value: "tbsp",
            name: `${t("tbsp")}`,
          },
          {
            value: "tsp",
            name: `${t("tsp")}`,
          },
        ]}
      />
      </span>
      <ButtonPrimary
        title={<HiOutlinePlus />}
        disabled={!name || !quantity || !unit}
        type="submit"
        aria-label="Submit other shopping list item button"
        name="Submit other shopping list item button"
      />
      </div>
    </form>
  );
}
