import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

//COMPONENTS
import { Nav, NavPage } from "./Navigation/Index";

//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css/free-mode";
import "swiper/css";

//ASSETS
import {
  HiOutlineClock,
  HiOutlineCog,
  HiOutlineShare,
  HiOutlineSwitchHorizontal,
  HiOutlineTrash,
  HiOutlineTruck,
  HiOutlineUser,
} from "react-icons/hi";
import { InputRadio, InputSearch } from "./formElements/Inputs";
import { FormAddShoppingItem } from "./formElements/FormAddShoppingItem";
//STYLES
import "./dashboard/Carrousel.scss";
import "./genericComponents/Tabs.scss";
import "../pages/planner/MenuPlanner.scss";

function Skeleton() {
  const { t } = useTranslation();
  const location = useLocation();

  const userPage = location.pathname.includes("/user/");
  const postPage = location.pathname.includes("/post/");

  const categoriesPage = location.pathname === "/recipe-categories";

  const explorePage = location.pathname === "/explore";
  const menuPlannerPage = location.pathname === "/menu-planner";
  const shoppingListPage = location.pathname === "/shopping-list";

  const accountPage = location.pathname === "/account";
  const notificationsPage = location.pathname === "/notifications";

  const defaultLoader =
    !postPage &&
    !userPage &&
    !categoriesPage &&
    !explorePage &&
    !menuPlannerPage &&
    !shoppingListPage &&
    !accountPage &&
    !notificationsPage;

  return (
    <>
      {postPage && (
        <div className="pageRecipeSkeleton">
          <div className="mainImage" />

          <div className="pageRecipeText">
            <div className="category" />
            <div className="name" />
            <div className="author">
              <HiOutlineUser size={11} color="#999" />
              <div className="user" />
              <div className="views" />
            </div>
            <div className="author">
              <HiOutlineClock size={11} color="#999" />
              <div className="date" />
            </div>

            <div className="timing">
              <div className="timingBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="timingBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="timingBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
            </div>
            <div className="nutrition">
              <div className="nutritionBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="nutritionBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="nutritionBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="nutritionBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="nutritionBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
              <div className="nutritionBox">
                <div className="icon" />
                <div className="title" />
                <div className="detail" />
              </div>
            </div>
            <div className="header" />
            <div className="ingredientsList">
              <div className="ingredient">
                <div className="image" />
                <div className="title" />
                <div className="quantity" />
                <div className="unit" />
              </div>
            </div>
            <div className="headerButton">
              <div className="header" />
              <div className="button" />
            </div>
          </div>
        </div>
      )}
      {userPage && (
        <div className="page">
          <div className="userProfileSkeleton">
            <NavPage title={<span className="text" />} />
            <div className="flexHeaderAccount">
              <div className="profileImageBox" />
              <div className="userStats">
                <button className="counterBtn">
                  <span className="count" />
                  <span className="text" />
                </button>
                <button className="counterBtn">
                  <span className="count" />
                  <span className="text" />
                </button>
                <button className="counterBtn">
                  <span className="count" />
                  <span className="text" />
                </button>
              </div>
            </div>
            <div className="name" />
            <div className="bio" />
            <div className="cta">
              <span className="followBtn" />
              <span className="messageBtn" />
              <span className="messageBtn" />
            </div>
            <br />
            <div className="flexBox">
              <div className="tabs" />
              <div className="tabs" />
              <div className="tabs" />
            </div>
            <div className="userRecipes">
              <div className="card" />
              <div className="card" />
              <div className="card" />`
            </div>
          </div>
        </div>
      )}
      {categoriesPage && (
        <div className="page">
          <div className="categoriesSkeleton">
            <NavPage title={<span className="text" />} />
            <div className="inputSearch" />
            <br />
            <div className="flexCategories">
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
              <div className="categoryBox">
                <span>
                  <p> </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {accountPage && (
        <>
          <Nav />
          <div className="page">
            <div className="userProfileSkeleton">
              <div className="flexHeaderAccount">
                <div className="profileImageBox" />
                <div className="userStats">
                  <button className="counterBtn">
                    <span className="count" />
                    <span className="text" />
                  </button>
                  <button className="counterBtn">
                    <span className="count" />
                    <span className="text" />
                  </button>
                  <button className="counterBtn">
                    <span className="count" />
                    <span className="text" />
                  </button>
                </div>
              </div>
              <div className="name" />
              <div className="bio" />
              <div className="cta">
                <span className="followBtn" />
                <span className="messageBtn" />
                <span className="messageBtn" />
              </div>
              <br />
              <div className="flexBox">
                <div className="tabs" />
                <div className="tabs" />
                <div className="tabs" />
              </div>
              <div className="userRecipes">
                <div className="card" />
                <div className="card" />
                <div className="card" />`
              </div>
            </div>
          </div>
        </>
      )}
      {explorePage && (
        <>
          <Nav />
          <div className="page">
            <div className="sliderBoxEmpty">
              <Swiper
                slidesPerView={3}
                spaceBetween={5}
                loop={true}
                freeMode={true}
                speed={5000}
                className="mySwiper"
                modules={[FreeMode]}
                autoplay={{
                  delay: 1,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <button className="carrouselItem" />
                  <span className="carrouselItemText" />
                </SwiperSlide>
                <SwiperSlide>
                  <button className="carrouselItem" />
                  <span className="carrouselItemText" />
                </SwiperSlide>
                <SwiperSlide>
                  <button className="carrouselItem" />
                  <span className="carrouselItemText" />
                </SwiperSlide>
                <SwiperSlide>
                  <button className="carrouselItem" />
                  <span className="carrouselItemText" />
                </SwiperSlide>
                <SwiperSlide>
                  <button className="carrouselItem" />
                  <span className="carrouselItemText" />
                </SwiperSlide>
              </Swiper>
            </div>
            <br />
            <div className="tabs skeleton">
              <div className="tabButtonsPrimary">
                <button className="tabButtonPrimary skeleton">
                  <h3> </h3>
                </button>
                <button className="tabButtonPrimary skeleton">
                  <h3> </h3>
                </button>
                <button className="tabButtonPrimary skeleton">
                  <h3>.</h3>
                </button>
                <button className="tabButtonPrimary skeleton">
                  <h3> </h3>
                </button>
                <button className="tabButtonPrimary skeleton">
                  <h3> </h3>
                </button>
              </div>
              <div className="tabButtonsSecondary">
                <button className="tabButtonSecondary skeleton"></button>
                <button className="tabButtonSecondary skeleton"></button>
                <button className="tabButtonSecondary skeleton"></button>
              </div>
              <div className="flexSearch">
                <form className="hasScanner hasFilter">
                  <InputSearch required />
                </form>
                <button className="filtersBtn">
                  <svg />
                </button>
                <button className="filtersBtn">
                  <svg />
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {menuPlannerPage && (
        <>
          <Nav />
          <div className="page">
            <h3 className="flexHeader marginTopNone skeletonHeader">
              {/* {t("Menu planner")} */}
            </h3>
            <div className="calendar">
              <div className="calendarHeader">
                <div className="flexBox">
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("MON")}</div>
                    </div>
                  </div>
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("TUE")}</div>
                    </div>
                  </div>
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("WED")}</div>
                    </div>
                  </div>
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("THU")}</div>
                    </div>
                  </div>
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("FRI")}</div>
                    </div>
                  </div>
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("SAT")}</div>
                    </div>
                  </div>
                  <div className="modalFull">
                    <div className="modalButton">
                      <div className="calendarDays">{t("SUN")}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper mySwiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="flexCalendar wrap">
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay active" type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />
                            <span className="progressEmpty snack" />
                            <span className="progressEmpty baby" />
                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay" disabled type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />

                            <span className="progressEmpty snack" />

                            <span className="progressEmpty baby" />

                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay" disabled type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />

                            <span className="progressEmpty snack" />

                            <span className="progressEmpty baby" />

                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay" disabled type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />

                            <span className="progressEmpty snack" />

                            <span className="progressEmpty baby" />

                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay" disabled type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />

                            <span className="progressEmpty snack" />

                            <span className="progressEmpty baby" />

                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay" disabled type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />

                            <span className="progressEmpty snack" />

                            <span className="progressEmpty baby" />

                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                      <div className="menuAdder" id="dayButton">
                        <button className="dateDay" disabled type="button">
                          <span className="dayNumber empty" />
                          <span className="flexProgress">
                            <span className="progressMiddle" />
                            <span className="progressEmpty breakfast" />
                            <span className="progressEmpty lunch" />
                            <span className="progressEmpty dinner" />
                            <span className="progressEmpty snack" />
                            <span className="progressEmpty baby" />
                            <span className="progressEmpty pet" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="selectedDayContent">
                <h4 className="flexHeader skeletonHeader small"> </h4>
              </div>
              <div className="flexCalendarFix">
                <h4 className="flexHeader">
                  <span></span>
                  <button type="button"></button>
                </h4>
                <div className="flexCalendar">
                  <div className="flexCalendarScroll"></div>
                </div>
              </div>
              <div className="flexCalendarFix">
                <h4 className="flexHeader">
                  <span></span>
                  <button type="button"></button>
                </h4>
                <div className="flexCalendar">
                  <div className="flexCalendarScroll"></div>
                </div>
              </div>
              <div className="flexCalendarFix">
                <h4 className="flexHeader">
                  <span></span>
                  <button type="button"></button>
                </h4>
                <div className="flexCalendar">
                  <div className="flexCalendarScroll"></div>
                </div>
              </div>
              <div className="flexCalendarFix">
                <h4 className="flexHeader">
                  <span></span>
                  <button type="button"></button>
                </h4>
                <div className="flexCalendar">
                  <div className="flexCalendarScroll"></div>
                </div>
              </div>
              <div className="flexCalendarFix">
                <h4 className="flexHeader">
                  <span></span>
                  <button type="button"></button>
                </h4>
                <div className="flexCalendar">
                  <div className="flexCalendarScroll"></div>
                </div>
              </div>
              <div className="flexCalendarFix">
                <h4 className="flexHeader">
                  <span></span>
                  <button type="button"></button>
                </h4>
                <div className="flexCalendar">
                  <div className="flexCalendarScroll"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {shoppingListPage && (
        <>
          <Nav />
          <div className="page">
            <h3 className="flexHeader marginTopNone">
              {t("Shopping list")}
              <button className="transparentButton">
                <HiOutlineClock />
              </button>
            </h3>
            <div className="flexRadio">
              <div className="checkboxCardBox">
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="All"
                    value="All"
                    label={t("All")}
                    defaultChecked={true}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Vegetables"
                    value="Vegetables"
                    label={t("Vegetables")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Fruits"
                    value="Fruits"
                    label={t("Fruits")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Meats"
                    value="Meats"
                    label={t("Meats")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Spices"
                    value="Spices"
                    label={t("Spices")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Oils and vinegar"
                    value="Oils and vinegar"
                    label={t("Oils and vinegar")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Fainoase"
                    value="Fainoase"
                    label={t("Fainoase")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Dairy and eggs"
                    value="Dairy and eggs"
                    label={t("Dairy and eggs")}
                  />
                </div>
                <div>
                  <InputRadio
                    type="radio"
                    name="businessType"
                    id="Pantry"
                    value="Pantry"
                    label={t("Pantry")}
                  />
                </div>
              </div>
            </div>
            <div className="listCart" />
            <div className="checkedList">
              <div className="listItems other">
                <FormAddShoppingItem />
              </div>
            </div>
            <div className="flexShare">
              <button className="flexShareBtn">
                <span>
                  <HiOutlineTrash />
                </span>
                <small> </small>
              </button>
              <button className="flexShareBtn">
                <span>
                  <HiOutlineShare />
                </span>
                <small> </small>
              </button>
              <button className="flexShareBtn">
                <span>
                  <HiOutlineSwitchHorizontal />
                </span>
                <small> </small>
              </button>
              <button className="flexShareBtn">
                <span>
                  <HiOutlineTruck />
                </span>
                <small> </small>
              </button>
            </div>
          </div>
        </>
      )}
      {notificationsPage && (
        <>
          <Nav />
          <div className="page">
            <h3 className="flexHeader marginTopNone">
              {t("Notifications")}
              <button className="transparentButton">
                <HiOutlineCog />
              </button>
            </h3>
            Empty notification cards
          </div>
        </>
      )}
      {defaultLoader && (
        <div className="loaderFull">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
}

export default Skeleton;
