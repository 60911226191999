import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./defaultStyles.scss";
import "./darkStyles.scss";

//INTERNATIONALIZATION
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

//REDUX
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/createStore";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

const RootComponent = () => {
  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch('https://us-central1-listeatco.cloudfunctions.net/api/get-country-code');
        const data = await response.json();
        const countryCode = data.countryCode.toLowerCase();
        localStorage.setItem("countryCode", countryCode);
        const language = countryCode === "ro" ? "ro" : "en";
        i18n.changeLanguage(language);
        console.log(`Country Code: ${countryCode}, Language Set: ${language}`);
      } catch (error) {
        console.error("Error fetching country code from Cloudflare:", error);
        i18n.changeLanguage('ro');
      }
    };

    fetchCountryCode();
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <PersistGate persistor={persistor}>
            <ScrollToTop />
            <App />
          </PersistGate>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
};



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RootComponent />);
