import { takeLatest, call, all, put, select, take } from "redux-saga/effects";
import {
  auth,
  handleUserProfile,
  getCurrentUser,
  GoogleProvider,
  firestore,
} from "./../../firebase/utils";
import userTypes from "./user.types";
import {
  signInSuccess,
  signOutUserSuccess,
  resetPasswordSuccess,
  userError,
  emailVerificationSuccess,
  emailVerificationError,
  signInError,
  emailVerificationStart,
  removePostSuccess,
  removePostError,
  savePostError,
  savePostSuccess,
  joyrideTourStart,
  updateSubscriptionPriceSuccess,
  updateSubscriptionPriceError,
} from "./user.actions";
import {
  handleEmailVerificationAPI,
  handleRemovePost,
  handleResetPasswordAPI,
  handleSavePost,
  handleUpdateBlockList,
  handleUpdateSubscriptionPrice,
  handleUpdateUserProfile,
} from "./user.helpers";
import { fetchPostStart } from "../posts/posts.actions";

export function* getSnapshotFromUserAuth(user, additionalData = {}) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    });
    const snapshot = yield userRef.get();
    const userData = {
      id: snapshot.id,
      isOnline: additionalData.isSignIn ? true : false,
      lastSeen: new Date(),
      ...snapshot.data(),
    };
    yield put(signInSuccess(userData));
    yield call(updateUserOnline, userData);
  } catch (err) {
    // console.error(err)
  }
}
// CHECK USER SESSION
export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;
    yield getSnapshotFromUserAuth(userAuth);
  } catch (err) {
    // console.log(err);
  }
}
export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}
// SEND EMAIL VERIFICATION
export function* sendEmailVerification() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);

    yield call(handleEmailVerificationAPI, currentUser.email);

    yield take(userTypes.EMAIL_VERIFICATION_SUCCESS);
    yield put(emailVerificationSuccess(true));
  } catch (error) {
    console.error("Error sending email verification:", error);
    yield put(emailVerificationError(error.message));
  }
}
export function* onEmailVerificationStart() {
  yield takeLatest(userTypes.EMAIL_VERIFICATION_START, sendEmailVerification);
}
// UPDATE EMAIL VERIFICATION STATUS
function* verifyEmail({ payload: oobCode }) {
  try {
    yield call([auth, auth.applyActionCode], oobCode);
    const user = auth.currentUser;
    if (user) {
      const userRef = firestore.collection('users').doc(user.uid);
      yield call([userRef, userRef.update], { isVerified: true });

      yield put(emailVerificationSuccess());
    }
  } catch (error) {
    yield put(emailVerificationError(error.message));
  }
}
export function* onVerifyEmailStart() {
  yield takeLatest(userTypes.VERIFY_EMAIL_START, verifyEmail);
}
export function* updateUserVerificationStatus() {
  yield put(emailVerificationSuccess(true));
}
export function* onEmailVerificationSuccess() {
  yield takeLatest(
    userTypes.EMAIL_VERIFICATION_SUCCESS,
    updateUserVerificationStatus
  );
}
// AUTH LOGIN
export function* emailSignIn({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user, { isSignIn: true });
  } catch (err) {
    if (err.code === "auth/wrong-password") {
      yield put(signInError(["Incorrect password"]));
    } else if (err.code === "auth/user-not-found") {
      yield put(signInError(["Email not found"]));
    } else if (err.code === "auth/too-many-requests") {
      yield put(
        signInError(["Too many requests, please try again in a few minutes"])
      );
    } else {
      yield put(signInError([err.message]));
    }
  }
}
export function* signInWithEmailAndPassword({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    yield put(signInError(err.message));
  }
}
export function* onEmailSignInStart() {
  yield takeLatest(userTypes.SIGN_IN_START, emailSignIn);
}
// AUTH REGISTER
export function* signUpUser({
  payload: {
    username,
    displayName,
    email,
    password,
    confirmPassword,
    photoURL,
  },
}) {
  if (password !== confirmPassword) {
    const err = ["Parolele nu seamna"];
    yield put(userError(err));
    return;
  }
  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const additionalData = {
      username,
      displayName,
      email,
      photoURL,
    };
    yield getSnapshotFromUserAuth(user, additionalData);
    yield put(emailVerificationStart({ email }));

    yield put(joyrideTourStart());
  } catch (err) {
    console.log(err);
  }
}
export function* onSignUpUserStart() {
  yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser);
}
// RESET PASSWORD
export function* resetPassword({ payload: { email } }) {
  try {
    yield call(handleResetPasswordAPI, email);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(userError(err));
  }
}
export function* onResetPasswordStart() {
  yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword);
}
//GOOGLE AUTH
export function* googleSignIn() {
  try {
    const { user,email } = yield auth.signInWithPopup(GoogleProvider);
    // const { user, email } = yield auth.signInWithRedirect(GoogleProvider);
    yield getSnapshotFromUserAuth(user, { isSignIn: true });
    yield put(emailVerificationStart({ email }));
  } catch (err) {
    // console.log(err);
  }
}
export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}
//UPDATE lastSeen AND isOnline
export function* updateUserOffline() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const { id } = currentUser;
    const userRef = firestore.collection("users").doc(id);

    if (currentUser && currentUser.id) {
      yield call([userRef, userRef.update], {
        lastSeen: new Date(),
        isOnline: false,
      });
    } else {
      // console.error("User does not have permission to update online status");
    }
  } catch (error) {
    // console.error("Error updating user online status:", error);
  }
}
export function* updateUserOnline() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);
    const { id, lastSeen } = currentUser;
    const userRef = firestore.collection("users").doc(id);

    if (currentUser && currentUser.id) {
      yield call([userRef, userRef.update], { lastSeen, isOnline: true });
    } else {
      // console.error("User does not have permission to update online status");
    }
  } catch (error) {
    // console.error("Error updating user online status:", error);
  }
}
//SIGN OUT
export function* signOutUser() {
  try {
    const currentUser = yield select((state) => state.user.currentUser);

    if (currentUser && currentUser.id) {
      yield call(updateUserOffline, {
        isOnline: false,
        lastSeen: new Date(),
      });
      yield call([auth, auth.signOut]);
      yield put(signOutUserSuccess());
    } else {
      console.error(
        "Cannot update online status: currentUser or id is missing"
      );
    }
  } catch (err) {
    console.error("Sign out error:", err);
  }
}
export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}
//UPDATE USER
export function* updateUserProfile({ payload }) {
  try {
    yield call(handleUpdateUserProfile, payload);
    yield put({ type: userTypes.UPDATE_USER_PROFILE_SUCCESS });
    // Success
  } catch (error) {
    yield put({ type: userTypes.UPDATE_USER_PROFILE_ERROR, error });
    // Error
  }
}
export function* onUpdateUserProfileStart() {
  yield takeLatest(userTypes.UPDATE_USER_PROFILE_START, updateUserProfile);
}
//ADD USER TO BLOCK LIST
export function* updateBlockList({ payload: { userID, targetID } }) {
  try {
    const updatedBlocked = yield call(handleUpdateBlockList, userID, targetID);
    yield put({
      type: userTypes.UPDATE_BLOCK_LIST_SUCCESS,
      payload: { userID, targetID, updatedBlocked },
    });
    // Success
  } catch (error) {
    yield put({ type: userTypes.UPDATE_BLOCK_LIST_ERROR, error });
    // Error
  }
}
export function* onUpdateBlockListStart() {
  yield takeLatest(userTypes.UPDATE_BLOCK_LIST_START, updateBlockList);
}
//SAVE AND REMOVE POST
function* savePost({ payload }) {
  try {
    const userID = auth.currentUser.uid;
    yield handleSavePost(userID, payload.postID);
    yield put(savePostSuccess({ postID: payload.postID }));
  } catch (error) {
    yield put(savePostError(error));
  }
}
export function* onSavePostStart() {
  yield takeLatest(userTypes.SAVE_POST_START, savePost);
}
function* removePost({ payload }) {
  try {
    const userID = auth.currentUser.uid;
    const updatedSavedPosts = yield call(handleRemovePost, userID, payload);
    yield put(removePostSuccess(updatedSavedPosts));
    yield put(fetchPostStart(payload));
  } catch (error) {
    yield put(removePostError(error));
  }
}
export function* onRemovePostStart() {
  yield takeLatest(userTypes.REMOVE_POST_START, removePost);
}

//UPDATE SUBSCRIPTION PRICE
function* updateSubscriptionPrice({ payload: { userID, subscriptionPrice } }) {
  try {
    yield call(handleUpdateSubscriptionPrice, userID, subscriptionPrice);
    yield put(updateSubscriptionPriceSuccess(subscriptionPrice));
  } catch (error) {
    yield put(updateSubscriptionPriceError(error.message));
  }
}
export function* onUpdateSubscriptionPriceStart() {
  yield takeLatest(userTypes.UPDATE_SUBSCRIPTION_PRICE_START, updateSubscriptionPrice);
}

export default function* userSagas() {
  yield all([
    call(onEmailSignInStart),
    call(onSignUpUserStart),
    call(onCheckUserSession),
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
    call(onSignOutUserStart),

    call(onVerifyEmailStart),
    call(onEmailVerificationStart),
    call(onEmailVerificationSuccess),

    call(onUpdateUserProfileStart),
    call(onUpdateBlockListStart),

    call(onSavePostStart),
    call(onRemovePostStart),

    call(onUpdateSubscriptionPriceStart),
  ]);
}
