import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  ButtonSecondary,
  ButtonTertiary,
} from "../components/formElements/Buttons";
//LANGUAGE
import { useTranslation } from "react-i18next";

//SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Mousewheel } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { createSelector } from "reselect";

import "./Homepage.scss";

const currentUserSelector = (state) => state.user.currentUser;

const mapState = createSelector([currentUserSelector], (currentUser) => ({
  currentUser,
}));

function Homepage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentUser } = useSelector(mapState);

  useEffect(() => {
    if (currentUser) {
      navigate("/explore");
    }
  }, [currentUser, navigate]);

  return (
    <>
      <div className="homepage">
        <div className="homepageCenter">
          <Swiper
            slidesPerView={1}
            className="mySwiper"
            pagination={{ clickable: true }}
            mousewheel={true}
            modules={[Keyboard, Pagination, Mousewheel]}
            keyboard={{
              enabled: true,
            }}
          >
            <SwiperSlide>
              <div className="cardHomepage">
                <h2>
                  {t("What's cooking")}
                  <br />
                  {t("today")}?
                </h2>
                <h3>
                  {t(
                    "All-in-one food experience - Automate the healthy life, save time and money"
                  )}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <picture>
                  <source
                    srcSet="/assets/homepage/salad.webp"
                    type="image/webp"
                  />
                  <img
                    srcSet="/assets/homepage/salad.webp 320w,
                       /assets/homepage/salad.webp 480w,
                        /assets/homepage/salad.webp 800w"
                    sizes="(max-width: 320px) 280px,
                      (max-width: 480px) 440px,
                      800px"
                    src="/assets/homepage/salad.webp"
                    alt="Listeat"
                    className="homepageImage"
                  />
                </picture>
              </div>
            </SwiperSlide>
            {/*<SwiperSlide>
              <div className="cardHomepage">
                <h2>{t("Save your favorite recipes")}</h2>
                <h3>
                  {t(
                    "Create a personalized recipe or inspire from thousants of recipes added by others"
                  )}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <LazyLoad height={200} offset={100}>
                  <picture>
                    <source
                      srcSet="/assets/homepage/recipeBook.webp"
                      type="image/webp"
                    />
                    <img
                      srcSet="/assets/homepage/recipeBook.webp 320w,
                       /assets/homepage/recipeBook.webp 480w,
                        /assets/homepage/recipeBook.webp 800w"
                      sizes="(max-width: 320px) 280px,
                      (max-width: 480px) 440px,
                      800px"
                      src="/assets/homepage/recipeBook.webp"
                      alt="Listeat"
                      className="vegetableCenter"
                      loading="lazy"
                    />
                  </picture>
                </LazyLoad>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cardHomepage">
                <h2>{t("Plan menus with zero headaches")}</h2>
                <h3>
                  {t(
                    "Take just a few minutes to organize your day, week or month in the right way"
                  )}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <LazyLoad height={200} offset={100}>
                  <picture>
                    <source
                      srcSet="/assets/homepage/mealPlanner.webp"
                      type="image/webp"
                    />
                    <img
                      srcSet="/assets/homepage/mealPlanner.webp 320w,
                       /assets/homepage/mealPlanner.webp 480w,
                        /assets/homepage/mealPlanner.webp 800w"
                      sizes="(max-width: 320px) 280px,
                      (max-width: 480px) 440px,
                      800px"
                      src="/assets/homepage/mealPlanner.webp"
                      alt="Listeat"
                      className="vegetableCenter"
                      loading="lazy"
                    />
                  </picture>
                </LazyLoad>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cardHomepage">
                <h2>{t("Organize the shopping list")}</h2>
                <h3>
                  {t(
                    "Optimize time and expenses with a shopping list and reduce food waste"
                  )}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <LazyLoad height={200} offset={100}>
                  <picture>
                    <source
                      srcSet="/assets/homepage/shoppingListHand.webp"
                      type="image/webp"
                    />
                    <img
                      srcSet="/assets/homepage/shoppingListHand.webp 320w,
                       /assets/homepage/shoppingListHand.webp 480w,
                        /assets/homepage/shoppingListHand.webp 800w"
                      sizes="(max-width: 320px) 280px,
                      (max-width: 480px) 440px,
                      800px"
                      src="/assets/homepage/shoppingListHand.webp"
                      alt="Listeat"
                      className="vegetableCenter"
                      loading="lazy"
                    />
                  </picture>
                </LazyLoad>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cardHomepage">
                <h2>{t("Personalize your experience")}</h2>
                <h3>
                  {t(
                    "Maintain a healthy diet you and your family and track your daily nutrition"
                  )}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <LazyLoad height={200} offset={100}>
                  <picture>
                    <source
                      srcSet="/assets/homepage/handClick.webp"
                      type="image/webp"
                    />
                    <img
                      srcSet="/assets/homepage/handClick.webp 320w,
                       /assets/homepage/handClick.webp 480w,
                        /assets/homepage/handClick.webp 800w"
                      sizes="(max-width: 320px) 280px,
                      (max-width: 480px) 440px,
                      800px"
                      src="/assets/homepage/handClick.webp"
                      alt="Listeat"
                      className="vegetableCenter"
                      loading="lazy"
                    />
                  </picture>
                </LazyLoad>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cardHomepage">
                <h2>{t("Connect with your friends")}</h2>
                <h3>
                  {t(
                    "Find you friends by linking social accounts and build your own community"
                  )}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <LazyLoad height={200} offset={100}>
                  <picture>
                    <source
                      srcSet="/assets/homepage/socialMedia.webp"
                      type="image/webp"
                    />
                    <img
                      srcSet="/assets/homepage/socialMedia.webp 320w,
                       /assets/homepage/socialMedia.webp 480w,
                        /assets/homepage/socialMedia.webp 800w"
                      sizes="(max-width: 320px) 280px,
                      (max-width: 480px) 440px,
                      800px"
                      src="/assets/homepage/socialMedia.webp"
                      alt="Listeat"
                      className="vegetableCenter"
                      loading="lazy"
                    />
                  </picture>
                </LazyLoad>
              </div>
            </SwiperSlide> */}
            <div className="homepageButtons">
              {!currentUser ? (
                <ButtonSecondary
                  title={t("Get started")}
                  onClick={() => navigate("/auth")}
                  name="Authentification"
                  aria-label="Authentification button"
                />
              ) : (
                <ButtonSecondary
                  title={t("Go to App")}
                  onClick={() => navigate("/explore")}
                  name="Go to App"
                  ariaabel="Go to App button"
                />
              )}
              <Link to="/find-more">
                <ButtonTertiary
                  title={t("Find more")}
                  name="Find more"
                  aria-label="Find more button"
                />
              </Link>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Homepage;
